<template>
  <div id="message" v-scroll-bottom="sessions">

    <div @click="closeView()">
      <ul v-if="currentList == '所有人'">
        0000
        <li v-for="entry in sessions[currentSession.nickname]" :key="entry.msgId">
          <p class="time"> <span>{{ entry.createTime }}

            </span> </p>
          <div class="main" v-if="entry.from != account">
            <p class="username">{{ entry.from }}</p>
            <img @dblclick="takeAShot" class="avatar"
               :src="entry.from == account ? 'https://www.aopiya.com/file/' + headImageUrl : 'https://www.aopiya.com/file/' + entry.headImageUrl"
              alt="" />
            <div v-if="entry.type == 1">
              <p class="text" v-html="entry.content"></p>
            </div>
            <div v-if="entry.type == 2">
              <!--图片预览与无法加载图片的图标-->
              <el-image :src="entry.content" :preview-src-list="[entry.content]" class="img">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
            <div class="file-container" v-if="entry.type == 3" @click="openLink(entry)">
              <div class="file-icon">
                <img
                    src="https://www.aopiya.com/file/abyFile/shopFile/introductionImage/40fab42e-6ff0-455f-936f-d85924cbc3db.png"
                    alt="File Icon" v-if="entry.content.split('.')[3] == 'pdf'" />
                  <img
                    src="https://www.aopiya.com/file/abyFile/shopFile/introductionImage/4513d105-fd62-4f98-b3b5-a317914059b1.png"
                    alt="File Icon"
                    v-if="entry.content.split('.')[3] == 'xls' || entry.content.split('.')[3] == 'xlsx'" />
                  <img
                    src="https://www.aopiya.com/file/abyFile/shopFile/introductionImage/97c2e98e-2a76-425f-a267-c816a696e23f.png"
                    alt="File Icon"
                    v-if="entry.content.split('.')[3] == 'doc' || entry.content.split('.')[3] == 'docx'" />
                  <img
                    src="https://www.aopiya.com/file/abyFile/shopFile/introductionImage/327ec734-6b53-4f3c-962e-5e3ade3eacdd.png"
                    alt="File Icon"
                    v-if="entry.content.split('.')[3] == 'ppt' || entry.content.split('.')[3] == 'pptx'" />
              </div>
              <div class="file-info">
                <div class="file-name">{{ entry.content.split('.')[1] }}</div>
                <div class="file-date">{{ entry.createTime.split(' ')[0] }}</div>
              </div>
            </div>
          </div>
          <div class="main1" v-else>
            <p class="username">{{ entry.from }}</p>
            <img @dblclick="takeAShot" class="avatar"
               :src="entry.from == account ? 'https://www.aopiya.com/file/' + headImageUrl : 'https://www.aopiya.com/file/' + entry.headImageUrl"
              alt="" />
            <div v-if="entry.type == 1" style="display: flex;justify-content: end;">
              <p class="text" v-html="entry.content"></p>
            </div>
            <div v-if="entry.type == 2" style="display: flex;justify-content: end;">
              <!--图片预览与无法加载图片的图标-->
              <el-image :src="entry.content" :preview-src-list="[entry.content]" class="img">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
            <div style="display: flex; justify-content: end;">
              <div class="file-container" v-if="entry.type == 3" @click="openLink(entry)"
                style="display: flex;justify-content: end;">
                <div class="file-icon">
                  <img
                    src="https://www.aopiya.com/file/abyFile/shopFile/introductionImage/40fab42e-6ff0-455f-936f-d85924cbc3db.png"
                    alt="File Icon" v-if="entry.content.split('.')[3] == 'pdf'" />
                  <img
                    src="https://www.aopiya.com/file/abyFile/shopFile/introductionImage/4513d105-fd62-4f98-b3b5-a317914059b1.png"
                    alt="File Icon"
                    v-if="entry.content.split('.')[3] == 'xls' || entry.content.split('.')[3] == 'xlsx'" />
                  <img
                    src="https://www.aopiya.com/file/abyFile/shopFile/introductionImage/97c2e98e-2a76-425f-a267-c816a696e23f.png"
                    alt="File Icon"
                    v-if="entry.content.split('.')[3] == 'doc' || entry.content.split('.')[3] == 'docx'" />
                  <img
                    src="https://www.aopiya.com/file/abyFile/shopFile/introductionImage/327ec734-6b53-4f3c-962e-5e3ade3eacdd.png"
                    alt="File Icon"
                    v-if="entry.content.split('.')[3] == 'ppt' || entry.content.split('.')[3] == 'pptx'" />
                </div>
                <div class="file-info">
                  <div class="file-name">{{ entry.content.split('.')[1] }}</div>
                  <div class="file-date">{{ entry.createTime.split(' ')[0] }}</div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <ul v-if="currentList == '客户'">
       
        <li v-for="entry in sessionsMember[currentSession.nickname]" :key="entry.msgId">
          <p class="time"> <span>{{ entry.createTime }} </span> </p>
          <div class="main" v-if="entry.from != account">
            <p class="username">{{ entry.from }}</p>
            <img @dblclick="takeAShot" class="avatar"
              :src="entry.from == account ? 'https://www.aopiya.com/file/' + headImageUrl : 'https://www.aopiya.com/file/' + entry.headImageUrl"
              alt="" />
            <div v-if="entry.type == 1">
              <p class="text" v-html="entry.content"></p>
            </div>
            <div v-if="entry.type == 2">
              <!--图片预览与无法加载图片的图标-->
              <el-image :src="entry.content" :preview-src-list="[entry.content]" class="img">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
            <div class="file-container" v-if="entry.type == 3" @click="openLink(entry)">
              <div class="file-icon">
                <img
                    src="https://www.aopiya.com/file/abyFile/shopFile/introductionImage/40fab42e-6ff0-455f-936f-d85924cbc3db.png"
                    alt="File Icon" v-if="entry.content.split('.')[3] == 'pdf'" />
                  <img
                    src="https://www.aopiya.com/file/abyFile/shopFile/introductionImage/4513d105-fd62-4f98-b3b5-a317914059b1.png"
                    alt="File Icon"
                    v-if="entry.content.split('.')[3] == 'xls' || entry.content.split('.')[3] == 'xlsx'" />
                  <img
                    src="https://www.aopiya.com/file/abyFile/shopFile/introductionImage/97c2e98e-2a76-425f-a267-c816a696e23f.png"
                    alt="File Icon"
                    v-if="entry.content.split('.')[3] == 'doc' || entry.content.split('.')[3] == 'docx'" />
                  <img
                    src="https://www.aopiya.com/file/abyFile/shopFile/introductionImage/327ec734-6b53-4f3c-962e-5e3ade3eacdd.png"
                    alt="File Icon"
                    v-if="entry.content.split('.')[3] == 'ppt' || entry.content.split('.')[3] == 'pptx'" />
              </div>
              <div class="file-info">
                <div class="file-name">{{ entry.content.split('.')[1] }}</div>
                <div class="file-date">{{ entry.createTime.split(' ')[0] }}</div>
              </div>
            </div>
          </div>
          <div class="main1" v-else>
            <p class="username">{{ entry.from }}</p>
            <img @dblclick="takeAShot" class="avatar"
               :src="entry.from == account ? 'https://www.aopiya.com/file/' + headImageUrl : 'https://www.aopiya.com/file/' + entry.headImageUrl"
              alt="" />
              
            <div v-if="entry.type == 1" style="display: flex;justify-content: end;">
              <p class="text" v-html="entry.content"></p>
            </div>
            <div v-if="entry.type == 2" style="display: flex;justify-content: end;">
              <!--图片预览与无法加载图片的图标-->
              <el-image :src="entry.content" :preview-src-list="[entry.content]" class="img">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
            <div style="display: flex; justify-content: end;">
              <div class="file-container" v-if="entry.type == 3" @click="openLink(entry)">
                <div class="file-icon">
                  <img
                    src="https://www.aopiya.com/file/abyFile/shopFile/introductionImage/40fab42e-6ff0-455f-936f-d85924cbc3db.png"
                    alt="File Icon" v-if="entry.content.split('.')[3] == 'pdf'" />
                  <img
                    src="https://www.aopiya.com/file/abyFile/shopFile/introductionImage/4513d105-fd62-4f98-b3b5-a317914059b1.png"
                    alt="File Icon"
                    v-if="entry.content.split('.')[3] == 'xls' || entry.content.split('.')[3] == 'xlsx'" />
                  <img
                    src="https://www.aopiya.com/file/abyFile/shopFile/introductionImage/97c2e98e-2a76-425f-a267-c816a696e23f.png"
                    alt="File Icon"
                    v-if="entry.content.split('.')[3] == 'doc' || entry.content.split('.')[3] == 'docx'" />
                  <img
                    src="https://www.aopiya.com/file/abyFile/shopFile/introductionImage/327ec734-6b53-4f3c-962e-5e3ade3eacdd.png"
                    alt="File Icon"
                    v-if="entry.content.split('.')[3] == 'ppt' || entry.content.split('.')[3] == 'pptx'" />
                </div>
                <div class="file-info">
                  <div class="file-name">{{ entry.content.split('.')[1] }}</div>
                  <div class="file-date">{{ entry.createTime.split(' ')[0] }}</div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <ul v-if="currentList == '群聊'">
        333
        <li v-for="entry in sessionsMember[currentSession.nickname]" :key="entry.msgId">
          <p class="time"> <span>{{ entry.createTime }}</span> </p>
          <div class="main">
            <p class="username">{{ entry.from }}</p>
            <img @dblclick="takeAShot" class="avatar"
            :src="entry.from == account ? 'https://www.aopiya.com/file/' + headImageUrl : 'https://www.aopiya.com/file/' + entry.headImageUrl" alt="" />
            <div v-if="entry.type == 1">
              <p class="text" v-html="entry.content"></p>
            </div>
            <div v-if="entry.type == 2">
              <!--图片预览与无法加载图片的图标-->
              <el-image :src="entry.content" :preview-src-list="[entry.content]" class="img">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
            <div class="file-container" v-if="entry.type == 3" @click="openLink(entry)">
              <div class="file-icon">
                
                <img
                    src="https://www.aopiya.com/file/abyFile/shopFile/introductionImage/40fab42e-6ff0-455f-936f-d85924cbc3db.png"
                    alt="File Icon" v-if="entry.content.split('.')[3] == 'pdf'" />
                  <img
                    src="https://www.aopiya.com/file/abyFile/shopFile/introductionImage/4513d105-fd62-4f98-b3b5-a317914059b1.png"
                    alt="File Icon"
                    v-if="entry.content.split('.')[3] == 'xls' || entry.content.split('.')[3] == 'xlsx'" />
                  <img
                    src="https://www.aopiya.com/file/abyFile/shopFile/introductionImage/97c2e98e-2a76-425f-a267-c816a696e23f.png"
                    alt="File Icon"
                    v-if="entry.content.split('.')[3] == 'doc' || entry.content.split('.')[3] == 'docx'" />
                  <img
                    src="https://www.aopiya.com/file/abyFile/shopFile/introductionImage/327ec734-6b53-4f3c-962e-5e3ade3eacdd.png"
                    alt="File Icon"
                    v-if="entry.content.split('.')[3] == 'ppt' || entry.content.split('.')[3] == 'pptx'" />
              </div>
              <div class="file-info">
                <div class="file-name">{{ entry.content.split('.')[1] }}</div>
                <div class="file-date">{{ entry.createTime.split(' ')[0] }}</div>
              </div>
            </div>
          </div>
        </li>

      </ul>

    </div>
    <div :class="{ hiddenDiv: !drawer, displatDiv: drawer }">
      <div style="height: 100%;flex :1;    height: 375px;" @click="closeView"></div>
      <div class="container" style="background: #ffffff;">

        <div class="hidden-div" :class="{ visible: drawer }" style="padding: 10px;background: #ffffff;">
          <div> <el-input class="search" type="text" size="mini" v-model="filterKey" placeholder="搜索群成员"
              prefix-icon="el-icon-search"></el-input></div>
          <div style="display: flex;flex-wrap: wrap;margin-top: 10px;">
            <div style="width:24%;margin-left: 1%;margin-bottom: 8px; position: relative; "
              v-for="(item, index) in sessionsMemberList" :key="index">
              <div style="display: flex;justify-content: center;align-items: center;"> <img class="avatar"
                  :src="item.userProfile ? item.userProfile : ''" style="width: 30px;height: 30px;" />
              </div>
              <div
                style="text-align: center;margin-top: 5px; width:68px;  white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                {{ item.nickName }}</div>
              <div class="close-button" @click="deleteUser(item)">✖</div> <!-- 打叉按钮 -->
            </div>
            <!-- <div
              style="width:24%;margin-left: 1%;margin-bottom: 8px; display: flex;justify-content: center;align-items: center;"
              v-for="(item, index) in sessionsMemberList" :key="index">

              <el-popover placement="right" width="400" trigger="click">
                gdgyasgdyagydgays
                <el-button slot="reference" type="text">
                  <div style="display: flex;justify-content: center;align-items: center;"> <img class="avatar"
                      :src="item.userProfile ? item.userProfile : ''" style="width: 30px;height: 30px;" />
                  </div>
                  <div
                    style="text-align: center;margin-top: 5px; width: 57px;  white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{ item.nickName }}</div>
                </el-button>
              </el-popover>
            </div> -->
            <div style="width:24%;margin-left: 1%;margin-bottom: 8px;" @click="dialogTableVisible = true">
              <div style="display: flex;justify-content: center;align-items: center;"> <img class="avatar"
                  src="https://www.aopiya.com/file/abyFile/shopFile/introductionImage/6797edbe-1c2b-4276-8762-8c63104a9d50.png"
                  alt="添加" style="width: 30px;height: 30px;" />
              </div>
              <div style="text-align: center;margin-top: 5px;"> 添加</div>
            </div>
          </div>
          <el-divider></el-divider>
          <div v-if="userContentMembers">
            <div>群聊名称</div>
            <el-input class="search" type="text" size="mini" v-model="userContentMembers.groupChatName"
              @blur="changegGroupChatName" :border="false"></el-input>
          </div>
          <el-divider></el-divider>
          <div>
            <div>群内昵称</div>
            <el-input class="search" type="text" size="mini" v-model="userNickName" :border="false"
              @blur="changeUserNickName"></el-input>
          </div>
          <el-divider></el-divider>
          <div style="text-align: center;">
            <div>退出群聊</div>

          </div>

        </div>
      </div>
    </div>
    <el-dialog title="用户列表" :visible.sync="dialogTableVisible" append-to-body width="420px">
      <div style="height: 500px;overflow: scroll;">

        <el-table :data="users" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="userProfile" width="80">
            <template #default="{ row }">
              <el-image class="avatar" :preview-src-list="row.userProfile ? [row.userProfile] : ''"
                :src="row.userProfile ? row.userProfile : ''">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="userName" min-width="180">
            <template #default="{ row }">
              {{ row.userName }} -{{ row.account }}
            </template>
          </el-table-column>

        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveUser">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { header } from "../../config/theme.config";
import {
  editGroupChat, dcChatcenterGroupchatmember, dcChatcenterGroupchatmemberdelete, queryGroupchatMsg, queryGroupchatMemberQL, dcChatcenterGroupchatmemberAdd
} from "@/api/basicDossier";
export default {
  name: "message",
  data() {
    return {

      // userContentMembers: JSON.parse(localStorage.getItem("userContentMembers")),
      headImageUrl: sessionStorage.getItem("headImageUrl"),
      userNickName: localStorage.getItem("userNickName"),
      userName: localStorage.getItem('chatName'),
      isVisible: false,
      filterKey: '',
      dialogTableVisible: false,
      multipleSelection: [],
      account: localStorage.getItem("loginAccount"),
    };
  },
  computed: {
    ...mapGetters({
      userContentMembers: "wechat/userContentMembers",
      sessions: "wechat/sessions",
      sessionsMember: "wechat/sessionsMember",
      currentList: "wechat/currentList",
      currentSession: "wechat/currentSession",
      errorImgUrl: "wechat/errorImgUrl",
      drawer: 'wechat/drawer',
      sessionsMemberList: "wechat/sessionsMemberList",
      users: "wechat/users",

      Members: "wechat/Member",
      isDot: "wechat/isDot",
      // userNickName: "wechat/userNickName",
    }),

  },
  filters: {
    time(date) {
      if (date) {
        date = new Date(date);
      }
      //当前的时间
      let currentDate = new Date();
      //与当前时间的日期间隔
      let timeInterval = currentDate.getDate() - date.getDate();
      //星期数组
      let weekdays = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      //时间范围
      let timeRange = "上午";
      if (date.getHours() > 12) {
        timeRange = "下午";
      }
      //如果与当前时间同日
      if (
        date.getMonth() == currentDate.getMonth() &&
        date.getDate() == currentDate.getDate()
      ) {
        return `${timeRange} ${date.getHours()}:${date.getMinutes()}`;
      }
      //在当前时间同一年且日期间隔在7天内
      if (
        date.getFullYear() == currentDate.getFullYear() &&
        timeInterval <= 6 &&
        timeInterval >= 1
      ) {
        //当前时间的前一天
        if (timeInterval == 1) {
          return `昨天 ${timeRange} ${date.getHours()}:${date.getMinutes()}`;
        } else {
          return `${weekdays[date.getDay()]
            } ${timeRange} ${date.getHours()}:${date.getMinutes()}`;
        }
      }
      //如果日期超过7天
      else
        return `${date.getFullYear()}-${date.getMonth() + 1
          }-${date.getDate()} ${timeRange}  ${date.getHours()}:${date.getMinutes()}`;
    },
  },
  directives: {
    /*这个是vue的自定义指令,官方文档有详细说明*/
    // 发送消息后滚动到底部,这里无法使用原作者的方法，也未找到合理的方法解决，暂用setTimeout的方法模拟
    "scroll-bottom"(el) {
      //console.log(el.scrollTop);
      setTimeout(function () {
        el.scrollTop += 9999;
      }, 1);
    },
  },
  created() {
    console.log(this.userContentMembers)
  },
  methods: {
    saveUser() {
      let arr = []
      this.multipleSelection.forEach(element => {
        arr.push({ account: element.account, nickName: element.userName, groupChatId: this.userContentMembers.groupChatId })
      });

      dcChatcenterGroupchatmemberAdd(arr).then(res => {
        if (res.code == 200) {
          queryGroupchatMemberQL({
            groupChatId: this.userContentMembers.groupChatId
          }).then(res => {

            res.data.forEach(element => {
              element.userProfile = "https://www.aopiya.com/file/" + element.headImageUrl
            });
            let myName = res.data.filter(item => item.account == localStorage.getItem('loginAccount'))
            console.log('myName', myName);
            this.$store.commit('wechat/initMessageMembersList', { content: res.data })
            localStorage.setItem('userNickName', myName[0].nickName)


          })
          this.dialogTableVisible = false
        }
      })

    },
    deleteUser(element) {
      dcChatcenterGroupchatmemberdelete({ account: element.account, groupChatId: this.userContentMembers.groupChatId }).then(resp => {
        if (resp.code == 200) {
          queryGroupchatMemberQL({
            groupChatId: this.userContentMembers.groupChatId
          }).then(res => {

            res.data.forEach(element => {
              element.userProfile = "https://www.aopiya.com/file/" + element.headImageUrl
            });
            let myName = res.data.filter(item => item.account == localStorage.getItem('loginAccount'))
            console.log('myName', myName);
            this.$store.commit('wechat/initMessageMembersList', { content: res.data })
            localStorage.setItem('userNickName', myName[0].nickName)


          })
        }
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
    },
    changegGroupChatName() {
      editGroupChat({ groupChatName: this.userContentMembers.groupChatName, groupChatId: this.userContentMembers.groupChatId }).then(res => {
        if (res.code == 200) {
          this.$store.dispatch("wechat/initData");
          this.$store.commit("wechat/changeCurrentSession", { userName: this.userContentMembers.groupChatName, nickname: this.userContentMembers.groupChatName });
        }
      })
    },
    changeUserNickName() {
      console.log("修改群聊名称", this.userNickName)
      dcChatcenterGroupchatmember({ nickName: this.userNickName, groupChatId: this.userContentMembers.groupChatId, account: localStorage.getItem('loginAccount') }).then(res => {
        if (res.code == 200) {
          queryGroupchatMsg({
            groupChatId: this.userContentMembers.groupChatId
          }).then(res => {
            // localStorage.setItem('userContentMembers', JSON.stringify(this.userContentMembers))
            this.$store.commit('wechat/setUserContentMembers', this.userContentMembers)
            this.$store.commit("wechat/changeCurrentSession", { userName: this.userContentMembers.groupChatName, nickname: this.userContentMembers.groupChatName });
            this.$store.commit('wechat/initMessageMembers', { to: this.userContentMembers.groupChatName, content: res.data })

          })
          queryGroupchatMemberQL({
            groupChatId: this.userContentMembers.groupChatId
          }).then(res => {

            res.data.forEach(element => {
              element.userProfile = "https://www.aopiya.com/file/" + element.headImageUrl
            });
            let myName = res.data.filter(item => item.account == localStorage.getItem('loginAccount'))
            console.log('myName', myName);
            this.$store.commit('wechat/initMessageMembersList', { content: res.data })
            localStorage.setItem('userNickName', myName[0].nickName)


          })
        }
      })
    },
    closeView() {
      console.log("关闭");
      this.$store.commit('wechat/setUserContentMembers', this.userContentMembers)
      this.$store.commit("wechat/setImmediaterawer", false);
    },
    openLink(entry) {
      window.open(entry.content, '_blank');
    },
    takeAShot(fromName, toName) {
      console.log("拍了一怕");
      let s = fromName + "拍了拍" + toName;
    },
  },
};
</script>

<style lang="scss" scoped>
#message {
  .no-border-input .el-input__inner {
    border: none;
    /* 去除边框 */
    box-shadow: none;
    /* 去除阴影 */
    outline: none;
    /* 去除聚焦时的轮廓 */
  }

  padding-left: 15px;
  height: 60%;
  max-height: 63%;
  overflow-y: scroll;
  overflow-x: hidden;

  ul {
    list-style-type: none;
    padding-left: 0px;

    li {
      margin-bottom: 15px;
    }
  }

  .time {
    text-align: center;
    margin: 7px 0;

    >span {
      display: inline-block;
      padding: 0 18px;
      font-size: 12px;
      color: #fff;
      background-color: #dcdcdc;
      border-radius: 2px;
    }
  }

  .main {
    .avatar {
      float: left;
      margin: 0 10px 0 0;
      border-radius: 3px;
      width: 30px;
      height: 30px;
    }

    .text {
      display: inline-block;
      padding: 0 10px;
      max-width: 80%;
      background-color: #fafafa;
      border-radius: 4px;
      line-height: 30px;
      margin-top: 12px;
    }

    .img {
      display: inline-block;
      height: 100px;
      width: 100px;
      margin-top: 15px;
    }

    .username {
      position: relative;
      left: 35px;
      top: 11px;
      margin: 0 0;
      padding: 0 0;
      border-radius: 4px;
      line-height: 15px;
      font-size: 10px;
      color: grey;
    }
  }

  .main1 {
    .avatar {
      float: right;
      margin: 0 0px 0 10px;
      border-radius: 3px;
      width: 30px;
      height: 30px;
    }

    .text {
      display: inline-block;
      padding: 0 10px;
      max-width: 80%;
      background-color: #fafafa;
      border-radius: 4px;
      line-height: 30px;
      margin-top: 12px;
    }

    .img {
      display: inline-block;
      height: 100px;
      width: 100px;
      margin-top: 15px;
    }

    .username {
      position: relative;
      left: 500px;
      top: 11px;
      margin: 0 0;
      padding: 0 0;
      border-radius: 4px;
      line-height: 15px;
      font-size: 10px;
      color: grey;
    }
  }

  .self {
    text-align: right;

    .avatar {
      float: right;
      margin: 0 0 0 10px;
      border-radius: 3px;
      width: 30px;
      height: 30px;
    }

    .text {
      display: inline-block;
      padding: 0 10px;
      max-width: 80%;
      background-color: #b2e281;
      border-radius: 4px;
      line-height: 30px;
      margin-top: 12px;
    }

    .img {
      display: inline-block;
      height: 100px;
      width: 100px;
      margin-top: 15px;
    }

    .username {
      //display: inline-block;
      position: relative;
      left: 310px;
      top: 11px;
      margin: 0 0;
      padding: 0 0;
      width: 200px;
      line-height: 15px;
      font-size: 10px;
      color: grey;
    }
  }

  .file-container {
    display: flex;
    align-items: center;

    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #f9f9f9;
    margin: 10px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 218px;
  }

  .file-icon {
    margin-right: 10px;
  }

  .file-icon img {
    width: 50px;
    /* 图标宽度 */
    height: 50px;
    /* 图标高度 */
  }

  .file-info {
    flex-grow: 1;
  }

  .file-name {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }

  .file-size {
    font-size: 14px;
    color: #666;
  }

  .file-date {
    font-size: 12px;
    color: #999;
  }

  // .container {
  //   position: relative;
  //   width: 100%;
  //   height: 26vh;
  //   overflow: hidden;
  //   /* 隐藏溢出的内容 */
  // }

  .hidden-div {
    position: absolute;
    top: 39%;
    right: -370px;
    transform: translateY(-50%);
    width: 300px;
    height: 375px;
    overflow: scroll;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    transition: right 0.5s ease;
  }


  .hidden-div.visible {
    right: 0;
    /* 显示时的位置 */
  }

  .hiddenDiv {
    display: none;
    transition: right 0.5s ease;
  }

  .displatDiv {
    display: flex;
    transition: right 0.5s ease;
  }

  .close-button {
    position: absolute;
    top: -9px;
    right: 0px;
    font-size: 15px;
    color: red;
    cursor: pointer;
    /* 鼠标悬停时显示手型光标 */
  }

}
</style>
