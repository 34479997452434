<template>
  <div id="toolbar">
    <!-- <el-image
      class="imgProfile"
      :src="user.userProfile ? user.userProfile : ''"
      :preview-src-list="user.userProfile ? [user.userProfile] : ''"
      :alt="user.nickname"
    >
      <div slot="error" class="image-slot">
        <i class="el-icon-picture-outline"></i>
      </div>
    </el-image> -->
    <div id="btnBar">
      <div style="margin-top: 40px;">
        <div class="topBtnBar">
          <el-popover placement="right" title="修改头像" width="140" trigger="click" style="height: 175px;">
            <el-upload class="avatar-uploader" action="#" :http-request="beforeAvatarUpload" :show-file-list="false"
              style="width: 100%">
              <div style="display: flex;justify-content: center;align-items: center;">
                <img v-if="imageUrl" :src="'https://www.aopiya.com/file/' + imageUrl" class="avatar"
                  style="width: 140px; height: 140px;">
                <img v-else :src="imageUrladd" class="avatar" style="width: 140px; height: 140px;">
              </div>
            </el-upload>
            <el-button class="toolBtn" size="small" slot="reference"> <img
                style="height: 20px;" :src="'https://www.aopiya.com/file/' + imageUrl" alt=""></el-button>
          </el-popover>
        </div>
        <div class="topBtnBar">
          <el-tooltip class="item" effect="dark" content="业务员" placement="right">
            <el-button @click="chooseChatList('客户')" class="toolBtn" size="small"> <img style="height: 20px;"
                src="https://www.aopiya.com/file/abyFile/shopFile/introductionImage/3251ba4f-eb0a-4ce3-8615-ccd31940af6f.png"
                alt=""></el-button>
          </el-tooltip>
        </div>
        <!-- <div class="topBtnBar">
          <el-tooltip class="item" effect="dark" content="群聊" placement="right">
            <el-button @click="chooseChatList('群聊')" class="toolBtn" size="small"> <img style="height: 20px;"
                src="https://www.aopiya.com/file/abyFile/shopFile/introductionImage/4639ced7-313d-4006-8f27-eefb3afb6983.png"
                alt=""></el-button>
          </el-tooltip>
        </div> -->
        <!-- <div class="topBtnBar">
          <el-tooltip class="item" effect="dark" content="部门" placement="right">
            <el-button @click="chooseChatList('部门')" class="toolBtn" size="small"> <img style="height: 20px;"
                src="https://www.aopiya.com/file/abyFile/shopFile/introductionImage/af8238d5-6905-4ee4-8da0-26b9fcc4b3d0.png"
                alt=""></el-button>
          </el-tooltip>
        </div>
        <div class="topBtnBar">
          <el-tooltip class="item" effect="dark" content="所有人" placement="right">
            <el-button @click="chooseChatList('所有人')" class="toolBtn" size="small">
              <img style="height: 20px;"
                src="https://www.aopiya.com/file/abyFile/shopFile/introductionImage/1b50f536-9833-4811-80ac-2cfe0e3c26fb.png"
                alt="">
            </el-button>
          </el-tooltip>
        </div> -->
      </div>
      <div class="bottomBtnBar">
        <el-tooltip class="item" effect="dark" content="退出" placement="right">
          <el-button @click="exitSystem" class="toolBtn" size="small"><i class="fa fa-sign-out fa-2x"
              aria-hidden="true"></i></el-button>
        </el-tooltip>
      </div>
    </div>
    <el-dialog title="意见反馈" :visible.sync="feedBackDialogVisible" class="feedbackDialog">
      <textarea class="feedbackInput" v-model="feedBackContent"> </textarea>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleFeedbackSend">确 定</el-button>
        <el-button @click="feedBackDialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import {
  changeHeadImageUrl
} from "@/api/basicDossier";
export default {
  name: "toolbar",
  data() {
    return {
      user: JSON.parse(window.sessionStorage.getItem("user")),
      feedBackDialogVisible: false,
      feedBackContent: "",
      headImageUrl: sessionStorage.getItem("headImageUrl"),
      imageUrl: sessionStorage.getItem("headImageUrl"),
      imageUrladd: 'https://www.aopiya.com/file/abyFile/shopFile/introductionImage/5a08499d-0e7b-445b-b1a1-fc5f34d39b90.png',

    };
  },
  methods: {

    //退出系统
    exitSystem() {
      this.$confirm("你是否要退出系统吗?", "系统提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.getRequest("/logout");
          sessionStorage.removeItem("user");
          //清除SessionStorage中保存的state
          if (sessionStorage.getItem("state")) {
            sessionStorage.removeItem("state");
          }
          //关闭连接
          this.$store.dispatch("wechat/disconnect");
          this.$router.replace("/");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    //选择聊天列表
    chooseChatList(listName) {
      this.$store.commit("wechat/setImmediaterawer", false);
      this.$store.commit("wechat/changeCurrentList", listName);
      console.log(listName)
    },
    //打开意见反馈对话框
    showFeedbackDialog() {
      this.feedBackContent = "";
      this.feedBackDialogVisible = true;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      console.log(file)
      //判断图片大小
      let isLt1M = file.file.size / 1024 / 1024 < 50;
      console.log(file);
      if (!isLt1M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      }
      //判断图片的格式
      let imgType = file.file.name.substring(file.file.name.lastIndexOf(".") + 1);
      imgType = imgType.toLowerCase();
      let isImg =
        imgType === "jpg" || imgType === "png" || imgType === "jpeg" || imgType === "gif";
      if (!isImg) {
        this.$message.error("上传图片格式不符合要求！");
      }
      if (isImg && isLt1M) {
        let formData = new FormData();

        formData.append("image", file.file);
        changeHeadImageUrl(formData).then((res) => {
          if (res.code == 200) {
            this.imageUrl = res.data.fileurl
          } else {
            this.$message.error("上传失败");
          }
        });
      }
    },

    //处理反馈消息邮件发送
    handleFeedbackSend() {
      let msgObj = {};
      msgObj.userId = this.user.id;
      msgObj.nickname = this.user.nickname;
      msgObj.username = this.user.username;
      msgObj.content = this.feedBackContent;
      console.log(msgObj);
      this.postRequest("/mail/feedback", msgObj).then((resp) => {
        if (resp) {
          this.feedBackDialogVisible = false;
        }
      });
    },
    //清空聊天记录
    clearChatHistory() {
      this.$confirm(
        "此操作将永久删除本地聊天记录(群聊记录会在下次登录时恢复), 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          //清除本地的localStorage中的聊天记录
          if (localStorage.getItem("chat-session")) {
            localStorage.removeItem("chat-session");
          }
          //清除Vuex中保存的记录
          this.$store.state.sessionStorage = {};
          //清除SessionStorage中保存的state
          if (sessionStorage.getItem("state")) {
            sessionStorage.removeItem("state");
          }
          this.$message({
            type: "success",
            message: "删除成功",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#toolbar {
  width: 100%;
  height: 100%;

  #btnBar {
    width: 100%;
    height: 82%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .imgProfile {
    width: 40px;
    height: 40px;
    horiz-align: center;
    margin: 25px 10px;
  }

  .toolBtn {
    background-color: #2e3238;
    border: 0;
    margin: 5px 5px;
  }

  .feedbackDialog {
    width: 1000px;
    height: 800px;
    margin: 10px auto;
    //background-color: #ECEAE8;
  }

  .feedbackInput {
    width: 450px;
    height: 200px;
    resize: none;
    padding: 0;
    margin: 0;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }


}

#moreList {
  margin: 0px;
  padding: 0px;
  background-color: #2e3238;

  li {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 5px;
    //padding-right: 40px;
    //border-bottom: 1px solid #292C33;
    list-style: none;
    cursor: pointer;

    &:hover {
      background-color: #abaaaa;
    }
  }
}
</style>
<style lang="scss">
/* el-popover是和app同级的，所以scoped的局部属性设置了无效 */
/* 需要设置全局style */
.el-popover.moreListPopoverClass {
  height: 150px;
  width: 150px;
  // margin: 0px;
  margin-left: 10px;
  padding: 0px;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #2e3238;
  border: none;
}
</style>
