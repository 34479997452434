var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"list"}},[_c('el-scrollbar',{attrs:{"wrap-class":"userList","wrap-style":"height:600px;","view-style":"height:100%;","native":false}},[(_vm.currentList == '客户')?_c('ul',[_c('p',{staticStyle:{"padding":"2px 4px","height":"20px"}},[_vm._v("客服列表")]),_vm._v(" "),_vm._l((_vm.groupChat),function(item){return _c('li',{class:{
        active: _vm.currentSession ? item.userName === _vm.currentSession.userName : false,
      },on:{"click":function($event){return _vm.changeCurrentSessionMembersGroupChat(item)}}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',[_c('el-badge',{attrs:{"is-dot":_vm.isDot[_vm.user.userName + '#' + item.userName]}},[_c('el-image',{staticClass:"avatar",attrs:{"preview-src-list":item.userProfile ? [item.userProfile] : '',"src":item.userProfile ? item.userProfile : '',"alt":item.nickname}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture-outline"})])])],1),_vm._v(" "),_c('p',{staticClass:"name"},[_vm._v(_vm._s(item.groupChatName))])],1)])])})],2):_vm._e(),_vm._v(" "),(_vm.currentList == '所有人')?_c('ul',[_c('p',{staticStyle:{"padding":"2px 4px","height":"20px"}},[_vm._v("用户列表")]),_vm._v(" "),_vm._l((_vm.users),function(item){return _c('li',{class:{
        active: _vm.currentSession ? item.userName === _vm.currentSession.userName : false,
      },on:{"click":function($event){return _vm.changeCurrentSession(item)}}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',[_c('el-badge',{attrs:{"is-dot":_vm.isDot[_vm.user.userName + '#' + item.userName]}},[_c('el-image',{staticClass:"avatar",attrs:{"preview-src-list":item.userProfile ? [item.userProfile] : '',"src":item.userProfile ? item.userProfile : '',"alt":item.nickname}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture-outline"})])])],1),_vm._v(" "),_c('p',{staticClass:"name"},[_vm._v(_vm._s(item.userName))])],1),_vm._v(" "),_c('div',[_c('el-badge',{attrs:{"value":item.isOnline == 1 ? '在线' : '离线',"type":item.isOnline == 1 ? 'danger' : 'info'}})],1)])])})],2):_vm._e(),_vm._v(" "),(_vm.currentList == '群聊')?_c('ul',[_c('p',{staticStyle:{"padding":"2px 4px","height":"20px"}},[_vm._v("群聊")]),_vm._v(" "),_vm._l((_vm.Members),function(item){return _c('li',{class:{
        active: _vm.currentSession ? item.userName === _vm.currentSession.userName : false,
      },on:{"click":function($event){return _vm.changeCurrentSessionMembers(item)}}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',[_c('el-badge',{attrs:{"is-dot":_vm.isDot[_vm.user.userName + '#' + item.userName]}},[_c('el-image',{staticClass:"avatar",attrs:{"preview-src-list":item.userProfile ? [item.userProfile] : '',"src":item.userProfile ? item.userProfile : '',"alt":item.nickname}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture-outline"})])])],1),_vm._v(" "),_c('p',{staticClass:"name"},[_vm._v(_vm._s(item.groupChatName))])],1)])])})],2):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }