<template>
    <div id="apps">
        <div class="toolbar">
            <toolbar></toolbar>
        </div>
        <div class="sidebar">
            <card></card>
            <list></list>
        </div>
        <div class="main">
            <chattitle></chattitle>
            <message></message>

            <usertext></usertext>
        </div>
    </div>
</template>

<script>

import card from "@/components/wechat/card";
import list from "@/components/wechat/list.vue";
import message from "@/components/wechat/message.vue";
import usertext from "@/components/wechat/usertext.vue";
import toolbar from "@/components/wechat/toolbar";
import chattitle from "@/components/wechat/chattitle";
import store from "@/store";
export default {
    components: { card, list, message, usertext, toolbar, chattitle },
    name: "chatIndex",
    data() {
        return {
           
        };
    },

};
</script>

<style lang="scss" scoped>
#apps {
    margin: 20px auto;
    width: 900px;
    height: 650px;
    overflow: hidden;
    border-radius: 10px;

    .sidebar,
    .main,
    .toolbar {
        height: 100%;
    }

    .toolbar {
        float: left;
        color: #f4f4f4;
        background-color: #2e3238;
        width: 60px;
    }

    .sidebar {
        float: left;
        color: #000000;
        background-color: #eceae8;
        width: 240px;
    }

    .main {
        position: relative;
        overflow: hidden;
        background-color: #eee;
    }
}
</style>
