<template>
  <div class="chatTitle">
    <span class="titleName">{{
      currentSession.nickname ? currentSession.nickname : ""
    }}</span>
    <el-button class="moreBtn" size="small" icon="el-icon-more" @click='addUserView()'></el-button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      currentSession: "wechat/currentSession",
      drawer: "wechat/drawer",
      currentList: "wechat/currentList",
    }),
  },

  methods: {
    addUserView() {
      console.log(this.currentList);
      if (this.currentList == '群聊' || this.currentList == '客户') {
        this.$store.commit("wechat/setImmediaterawer", !this.drawer);
      }
      // this.$store.commit("wechat/setImmediaterawer", !this.drawer);
      console.log(this.drawer);
    },
  },
};
</script>

<style scoped>
.chatTitle {
  height: 50px;
  width: 100%;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid #c7d2db;
}

.moreBtn {
  background-color: #eee;
  border: 0;
  height: 40px;
}

.titleName {
  margin: 10px 20px;
}
</style>
