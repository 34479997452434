<template>
  <div id="list">
    <!-- <ul v-if="currentList == '群聊'">
 
      <p style="padding: 2px 4px; height: 20px">群聊列表</p>
      <li
        :class="{ active: currentSession ? '群聊' == currentSession.username : false }"
        v-on:click="changeCurrentSession(chatObj)"
      >
        <img
          class="avatar"
          src="https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1268761962,3976237305&fm=26&gp=0.jpg"
        />
        <el-badge :is-dot="isDot[user.username + '#群聊']"
          ><p class="name">群聊</p></el-badge
        >
      </li>
    </ul>
    <ul v-if="currentList == '机器人'">
      <p style="padding: 2px 4px; height: 20px">快来和机器人聊天吧！</p>
      <li
        :class="{ active: currentSession ? '机器人' == currentSession.username : false }"
        v-on:click="changeCurrentSession(robotObj)"
      >
        <img
          class="avatar"
          src="https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=2548892998,499717296&fm=26&gp=0.jpg"
        />
        <p class="name">瓦力(智能回复)</p>
      </li>
    </ul> -->
    <!--用户列表-->
    <el-scrollbar wrap-class="userList" wrap-style="height:600px;" view-style="height:100%;" :native="false">
      <ul v-if="currentList == '客户'">
        <p style="padding: 2px 4px; height: 20px">客服列表</p>

        <li v-for="item in groupChat" :class="{
          active: currentSession ? item.userName === currentSession.userName : false,
        }" v-on:click="changeCurrentSessionMembersGroupChat(item)">
          <!--   :class="[item.id === currentSession ? 'active':'']" -->

          <div style="display: flex; justify-content: space-between">
            <div>
              <el-badge :is-dot="isDot[user.userName + '#' + item.userName]" style="">
                <el-image class="avatar" :preview-src-list="item.userProfile ? [item.userProfile] : ''"
                  :src="item.userProfile ? item.userProfile : ''" :alt="item.nickname">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </el-badge>
              <p class="name">{{ item.groupChatName }}</p>
            </div>

          </div>
        </li>
      </ul>
      <ul v-if="currentList == '所有人'">
        <p style="padding: 2px 4px; height: 20px">用户列表</p>

        <li v-for="item in users" :class="{
          active: currentSession ? item.userName === currentSession.userName : false,
        }" v-on:click="changeCurrentSession(item)">
          <!--   :class="[item.id === currentSession ? 'active':'']" -->

          <div style="display: flex; justify-content: space-between">
            <div>
              <el-badge :is-dot="isDot[user.userName + '#' + item.userName]" style="">
                <el-image class="avatar" :preview-src-list="item.userProfile ? [item.userProfile] : ''"
                  :src="item.userProfile ? item.userProfile : ''" :alt="item.nickname">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </el-badge>
              <p class="name">{{ item.userName }}</p>
            </div>
            <div>
              <el-badge :value="item.isOnline == 1 ? '在线' : '离线'"
                :type="item.isOnline == 1 ? 'danger' : 'info'"></el-badge>
            </div>
          </div>
        </li>
      </ul>
      <ul v-if="currentList == '群聊'">
        <p style="padding: 2px 4px; height: 20px">群聊</p>

        <li v-for="item in Members" :class="{
          active: currentSession ? item.userName === currentSession.userName : false,
        }" v-on:click="changeCurrentSessionMembers(item)">
          <!--   :class="[item.id === currentSession ? 'active':'']" -->

          <div style="display: flex; justify-content: space-between">
            <div>
              <el-badge :is-dot="isDot[user.userName + '#' + item.userName]" style="">
                <el-image class="avatar" :preview-src-list="item.userProfile ? [item.userProfile] : ''"
                  :src="item.userProfile ? item.userProfile : ''" :alt="item.nickname">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </el-badge>
              <p class="name">{{ item.groupChatName }}</p>
            </div>

          </div>
        </li>
      </ul>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  imageHttps
} from '@/config'
import {
  chatCenterQueryChatToSbHistory, queryGroupchatMsg, queryGroupchatMemberQL
} from "@/api/basicDossier";

export default {
  name: "list",
  data() {
    return {
      chatObj: { userName: "群聊", nickname: "群聊" }, //群聊实体对象（为方法复用而构造，对于User对象）
      robotObj: {
        userName: "机器人",
        nickname: "机器人",

        userProfile:
          "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=2548892998,499717296&fm=26&gp=0.jpg",
      },

      user: { userName: 'ssss' }
    };
  },
  computed: {
    ...mapGetters({
      userContentMembers: "wechat/userContentMembers",
      users: "wechat/users",
      Members: "wechat/Member",
      currentSession: "wechat/currentSession",
      isDot: "wechat/isDot",
      currentList: "wechat/currentList",
      groupChat: "wechat/groupChat",
      // user: "wechat/currentUser",
    }),
  },
  // created() {
  //   dchatCenterQueryUsers({}).then(res => {
  //     this.users = res.data
  //   })
  // },
  methods: {
    changeCurrentSession: function (currentSession) {

      console.log(currentSession);
      chatCenterQueryChatToSbHistory({
        to: currentSession.account
      }).then(res => {

        localStorage.setItem('userContent', JSON.stringify(currentSession))
        this.$store.commit("wechat/changeCurrentSession", { userName: currentSession.userName, nickname: currentSession.account });
        this.$store.commit('wechat/initMessage', { to: currentSession.account, content: res.data })
      })

    },
    changeCurrentSessionMembersGroupChat: function (currentSession) {

      console.log(currentSession);
      queryGroupchatMsg({
        groupChatId: currentSession.groupChatId
      }).then(res => {
        console.log('currentSession',currentSession);
        // localStorage.setItem('userContentMembers', JSON.stringify(currentSession))
        this.$store.commit("wechat/changeCurrentSession", { userName: currentSession.groupChatName, nickname: currentSession.groupChatName });
        this.$store.commit('wechat/initMessageMembers', { to: currentSession.groupChatName, content: res.data })
        this.$store.commit('wechat/setUserContentMembers', currentSession)
      })
      queryGroupchatMemberQL({
        groupChatId: currentSession.groupChatId
      }).then(res => {

        res.data.forEach(element => {
          element.userProfile = "https://www.aopiya.com/file/" + element.headImageUrl
        });
        let myName = res.data.filter(item => item.account == localStorage.getItem('loginAccount'))
        console.log('myName', myName);
        this.$store.commit('wechat/initMessageMembersList', { content: res.data })
        localStorage.setItem('userNickName', myName[0].nickName)


      })
    },
    changeCurrentSessionMembers: function (currentSession) {

      console.log(currentSession);
      queryGroupchatMsg({
        groupChatId: currentSession.groupChatId
      }).then(res => {
        this.$store.commit('wechat/setUserContentMembers', currentSession)
        this.$store.commit("wechat/changeCurrentSession", { userName: currentSession.groupChatName, nickname: currentSession.groupChatName });
        this.$store.commit('wechat/initMessageMembers', { to: currentSession.groupChatName, content: res.data })

      })
      queryGroupchatMemberQL({
        groupChatId: currentSession.groupChatId
      }).then(res => {

        res.data.forEach(element => {
          element.userProfile = "https://www.aopiya.com/file/" + element.headImageUrl
        });
        let myName = res.data.filter(item => item.account == localStorage.getItem('loginAccount'))
        console.log('myName', myName);
        this.$store.commit('wechat/initMessageMembersList', { content: res.data })
        if (myName.length != 0) {
          localStorage.setItem('userNickName', myName[0].nickName)
        }



      })
    },
  }
};
</script>

<style lang="scss" scoped>
#list {
  ul {
    margin-left: 0px;
    padding-left: 0px;
    margin-left: 2px;
  }

  li {
    padding-top: 14px;
    padding-bottom: 14px;
    //padding-right: 40px;
    //border-bottom: 1px solid #292C33;
    list-style: none;
    cursor: pointer;

    &:hover {
      background-color: #d8d6d6;
    }
  }

  li.active {
    /*注意这个是.不是冒号:*/
    background-color: #c8c6c6;
  }

  .avatar {
    border-radius: 2px;
    width: 30px;
    height: 30px;
    vertical-align: middle;
  }

  .name {
    display: inline-block;
    margin-left: 15px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .stateItem {
    //在线状态的样式
    /*position: absolute;*/
    /*left: 160px;*/
    //margin-left: 100px;
    //margin-right: 10px;
  }

  .userList {
    max-height: 600px;
  }

  .el-scrollbar__wrap.default-scrollbar__wrap {
    overflow-x: auto;
  }
}
</style>
