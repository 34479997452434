<template>
  <div id="uesrtext">
    <div>
      <el-popover placement="top-start" width="400" trigger="click" class="emoBox">
        <div class="emotionList">
          <a href="javascript:void(0);" @click="getEmo(index)" v-for="(item, index) in faceList" :key="index"
            class="emotionItem">{{ item }}</a>
        </div>
        <el-button id="emojiBtn" class="emotionSelect" slot="reference">
          <i class="fa fa-smile-o" aria-hidden="true"></i>
        </el-button>
      </el-popover>
      <el-upload class="upload-btn" action="#" :http-request="beforeAvatarUpload" :show-file-list="false" ref="uploads"
        accept=".jpg,.jpeg,.png,.JPG,JPEG,.PNG,.gif,.GIF">
        <el-button id="uploadImgBtn" icon="el-icon-picture-outline"></el-button>
      </el-upload>
      <el-upload class="upload-btn1" action="#" :http-request="beforeAvatarUpload1" :show-file-list="false"
        ref="uploads1" accept=".pdf,.ppt,.doc,.docx,.pptx,.xls,.xlsx">
        <el-button id="uploadImgBtn1" icon="el-icon-folder"></el-button>
      </el-upload>
    </div>
    <textarea id="textarea" placeholder="按 Ctrl + Enter 发送" v-model="content" >
    </textarea>
    <el-button id="sendBtn" type="primary" size="mini" @click="addMessageByClick">发送(S)</el-button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  chatCenterAddFile,
} from "@/api/basicDossier";
// import { data } from "jquery";
const appData = require("@/utils/emoji.json"); //引入存放emoji表情的json文件

export default {
  name: "uesrtext",
  data() {
    return {
      faceList: [], //表情包数据
      content: "",
    };
  },
  mounted() {
    for (let i in appData) {
      //读取json文件保存数据给数组
      this.faceList.push(appData[i].char);
    }
  },

  computed: {
    ...mapGetters({
      userContentMembers: "wechat/userContentMembers",
      sessions: "wechat/sessions",
      currentSession: "wechat/currentSession",
      stomp: "wechat/stomp",
      currentUser: "wechat/currentUser",
      currentUserInfo: "wechat/currentUserInfo",
      currentList: "wechat/currentList",
    }),
  },
  methods: {
    getDate(currentDate) {
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，所以要加 1
      const day = String(currentDate.getDate()).padStart(2, '0');
      const hours = String(currentDate.getHours()).padStart(2, '0'); // 获取小时
      const minutes = String(currentDate.getMinutes()).padStart(2, '0'); // 获取分钟
      const seconds = String(currentDate.getSeconds()).padStart(2, '0'); // 获取秒

      // 格式化为 YYYY-MM-DD HH:mm:ss
      const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return formattedDateTime;
    },
    addMessageByClick() {
      if (!this.content || this.content.match(/^[ ]*$/)) {
        this.$message({
          showClose: true,
          message: "不能发送空白信息",
        });
        return;
      }
      if (this.currentList == "所有人") {
        console.log(1)
        let msgObj = new Object();
        msgObj.content = this.content;
        msgObj.messageTypeId = 1;
        //发送群聊消息
        console.log('this.currentSession', this.currentSession)
        msgObj.from = localStorage.getItem('loginAccount');
        msgObj.content = this.content
        msgObj.to = this.currentSession.nickname;
        msgObj.headImageUrl=sessionStorage.getItem('headImageUrl')
        msgObj.type = 1
        msgObj.createTime = this.getDate(new Date())
        this.stomp.send("/ws/chat", {}, JSON.stringify(msgObj));
        //提交私聊消息记录
        this.$store.commit("wechat/sendAddMessage", msgObj);

      }
      if (this.currentList == "客户") {
        let msgObj = new Object();
        msgObj.content = this.content;
        msgObj.messageTypeId = 1;
        //发送群聊消息
        console.log('this.currentSession', this.currentSession)
        msgObj.from = localStorage.getItem('loginAccount');
        msgObj.content = this.content
        msgObj.to = this.userContentMembers.groupChatId
        msgObj.type = 1
        msgObj.groupChatId = this.userContentMembers.groupChatId
        msgObj.createTime = this.getDate(new Date())
        msgObj.chatName = this.currentSession.nickname
        msgObj.headImageUrl=sessionStorage.getItem('headImageUrl')
        //提交私聊消息记录

        console.log(this.content);
        this.stomp.send("/ws/groupChat", {}, JSON.stringify(msgObj));
        this.$store.commit("wechat/sendAddMessageChat", msgObj);

      }
      //清空输入框
      this.content = "";
    },
    addMessage(e) {
      if (e.ctrlKey && e.keyCode === 13 && this.content.length) {
        this.addMessageByClick();
      }
    },
    /**
     *       图片上传的方法
     */
    //上传前
    beforeAvatarUpload(file) {
      console.log(file)
      //判断图片大小
      let isLt1M = file.file.size / 1024 / 1024 < 50;
      console.log(file);
      if (!isLt1M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      }
      //判断图片的格式
      let imgType = file.file.name.substring(file.file.name.lastIndexOf(".") + 1);
      imgType = imgType.toLowerCase();
      let isImg =
        imgType === "jpg" || imgType === "png" || imgType === "jpeg" || imgType === "gif";
      if (!isImg) {
        this.$message.error("上传图片格式不符合要求！");
      }
      if (isImg && isLt1M) {
        let formData = new FormData();

        formData.append("file", file.file);
        chatCenterAddFile(formData).then((res) => {
          if (res.code == 200) {
            this.$message.success("上传成功");
            if (this.currentList == "所有人") {

              let msgObj = new Object();

              //发送群聊消息
              console.log('this.currentSession', this.currentSession)
              msgObj.from = localStorage.getItem('loginAccount');
              msgObj.content = 'https://www.aopiya.com/file/' + res.data.fileurl
              msgObj.to =this.userContentMembers.groupChatId
              msgObj.type = 2
              msgObj.headImageUrl=sessionStorage.getItem('headImageUrl')
              msgObj.createTime = this.getDate(new Date())
              this.stomp.send("/ws/chat", {}, JSON.stringify(msgObj));
              //提交私聊消息记录
              this.$store.commit("wechat/sendAddMessage", msgObj);
            }
            if (this.currentList == "客户") {

              let msgObj = new Object();


              //发送群聊消息
              msgObj.from = localStorage.getItem('loginAccount');
              msgObj.content = 'https://www.aopiya.com/file/' + res.data.fileurl
              msgObj.to = this.userContentMembers.groupChatId
              msgObj.type = 2
              msgObj.groupChatId = this.userContentMembers.groupChatId
              msgObj.createTime = this.getDate(new Date())
              msgObj.chatName = this.currentSession.nickname
              msgObj.headImageUrl=sessionStorage.getItem('headImageUrl')
              //发送群聊消息

              this.stomp.send("/ws/groupChat", {}, JSON.stringify(msgObj));
              this.$store.commit("wechat/sendAddMessageChat", msgObj);
            }
          } else {
            this.$message.error("上传失败");
          }
        });
      }
    },
    //上传前
    beforeAvatarUpload1(file) {
      console.log(file)
      //判断图片大小
      let isLt1M = file.file.size / 1024 / 1024 < 50;
      console.log(file);
      if (!isLt1M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      }
      //判断图片的格式
      let imgType = file.file.name.substring(file.file.name.lastIndexOf(".") + 1);
      imgType = imgType.toLowerCase();
      let isImg =
        imgType === "pdf" || imgType === "ppt" || imgType === "docx" || imgType === "pptx" || imgType === "xls" || imgType === "xlsx" || imgType === "doc";
      if (!isImg) {
        this.$message.error("上传图片格式不符合要求！");
      }
      if (isImg && isLt1M) {
        let formData = new FormData();

        formData.append("file", file.file);
        chatCenterAddFile(formData).then((res) => {
          if (res.code == 200) {
            this.$message.success("上传成功");
            if (this.currentList == "所有人") {
              let msgObj = new Object();

              //发送群聊消息
              console.log('this.currentSession', this.currentSession)
              msgObj.from = localStorage.getItem('loginAccount');
              msgObj.content = 'https://www.aopiya.com/file/' + res.data.fileurl
              msgObj.to = this.currentSession.nickname;
              msgObj.type = 3
              msgObj.createTime = this.getDate(new Date())
              msgObj.headImageUrl=sessionStorage.getItem('headImageUrl')
              this.stomp.send("/ws/chat", {}, JSON.stringify(msgObj));
              //提交私聊消息记录
              this.$store.commit("wechat/sendAddMessage", msgObj);
            }
            if (this.currentList == "客户") {

              let msgObj = new Object();


              //发送群聊消息
              msgObj.from = localStorage.getItem('loginAccount');
              msgObj.content = 'https://www.aopiya.com/file/' + res.data.fileurl
              msgObj.to =this.userContentMembers.groupChatId
              msgObj.type = 3
              msgObj.groupChatId =this.userContentMembers.groupChatId
              msgObj.createTime = this.getDate(new Date())
              msgObj.chatName = this.currentSession.nickname
              msgObj.headImageUrl=sessionStorage.getItem('headImageUrl')
              //发送群聊消息

              this.stomp.send("/ws/groupChat", {}, JSON.stringify(msgObj));
              this.$store.commit("wechat/sendAddMessageChat", msgObj);
            }
          } else {
            this.$message.error("上传失败");
          }
        });
      }
    },
    // 图片上传成功
    imgSuccess(response, file, fileList) {
      console.log("图片url为：" + response);
      let msgObj = new Object();
      msgObj.content = response;
      //设置消息类型为图片
      msgObj.messageTypeId = 2;
      if (this.currentSession.username == "群聊") {
        this.stomp.send("/ws/groupChat", {}, JSON.stringify(msgObj));
      } else {
        msgObj.from = this.currentUser.username;
        msgObj.fromNickname = this.currentUser.nickname;
        msgObj.to = this.currentSession.username;
        msgObj.headImageUrl=sessionStorage.getItem('headImageUrl')
        this.stomp.send("/ws/chat", {}, JSON.stringify(msgObj));
        //提交私聊消息记录
        this.$store.commit("addMessage", msgObj);
      }
    },
    // 图片上传失败
    imgError(err, file, fileList) {
      this.$message.error("上传失败");
    },
    //获取Emoji
    getEmo(index) {
      var textArea = document.getElementById("textarea");
      //将选中的表情插入到输入文本的光标之后
      function changeSelectedText(obj, str) {
        if (window.getSelection) {
          // 非IE浏览器
          textArea.setRangeText(str);
          // 在未选中文本的情况下，重新设置光标位置
          textArea.selectionStart += str.length;
          textArea.focus();
        } else if (document.selection) {
          // IE浏览器
          obj.focus();
          var sel = document.selection.createRange();
          sel.text = str;
        }
      }
      changeSelectedText(textArea, this.faceList[index]);
      this.content = textArea.value; // 要同步data中的数据
      // console.log(this.faceList[index]);
      return;
    },
  },
};
</script>

<style lang="scss">
/* el-popover是和app同级的，所以scoped的局部属性设置无效 */
/* 需要设置全局style */
.el-popover {
  height: 200px;
  width: 450px;
  overflow: scroll;
  overflow-x: auto;
}
</style>

<style lang="scss" scoped>
#uesrtext {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 30%;
  border: solid 1px #ddd;
  background-color: white;

  >textarea {
    padding: 10px;
    width: 95%;
    height: 58%;
    border: none;
    outline: none;
    resize: none; //禁止拉伸
  }

  #sendBtn {
    float: right;
    margin-right: 10px;
  }

  #uploadImgBtn {
    border: none;
    padding-bottom: 0px;
    margin-bottom: 0px;

  }

  #uploadImgBtn1 {
    border: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
    padding-left: 12px;
  }

  #uploadImgBtn1:hover {
    background-color: white;
  }

  #uploadImgBtn:hover {
    background-color: white;
  }

  #emojiBtn {
    border: none;
    padding-right: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  #emojiBtn:hover {
    background-color: white;
  }

  .upload-btn {
    display: inline-block;
  }

  .upload-btn1 {
    display: inline-block;
  }
}

.emotionList {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
}

.emotionItem {
  width: 10%;
  font-size: 20px;
  text-align: center;
}

/*包含以下四种的链接*/
.emotionItem {
  text-decoration: none;
}

/*正常的未被访问过的链接*/
.emotionItem:link {
  text-decoration: none;
}

/*已经访问过的链接*/
.emotionItem:visited {
  text-decoration: none;
}

/*鼠标划过(停留)的链接*/
.emotionItem:hover {
  text-decoration: none;
}

/* 正在点击的链接*/
.emotionItem:active {
  text-decoration: none;
}
</style>
