<template>
  <div id="index">
    <div style="position: relative; width: 100%">
      <video :autoplay="autoplayTrue" muted loop style="height: 99.5vh; object-fit: cover; width: 100%"
        @click="buyGoodp">
        <source src="https://www.aopiya.com/file/abyFile/backGround/CB.mp4" type="video/mp4"
          style="width: 100%; height: 100%" />
      </video>
      <div style="
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          bottom: 0px;
          background: white;
        ">
        <div style="display: flex; align-items: center">
          <div @mouseover="resetBorderColor1()" @mouseout="resetBorderColorp1()" @click="buyGoodIntroduce()"
            style="margin-right: 45px">
            <img :src="imageItem1" style="width: 70px; height: 70px; padding: 20px" class="columnItem" />
          </div>

          <div @mouseover="resetBorderColor2()" @mouseout="resetBorderColorp2()" @click="buyGoods(1)"
            style="margin-right: 45px">
            <img :src="imageItem2" style="width: 70px; height: 70px; padding: 10px" class="columnItem" />
          </div>

          <div @mouseover="resetBorderColor3()" @mouseout="resetBorderColorp3()" @click="buyGoods(2)"
            style="margin-right: 45px">
            <img :src="imageItem3" style="width: 70px; height: 70px; padding: 10px" class="columnItem" />
          </div>

          <div @mouseover="resetBorderColor4()" @mouseout="resetBorderColorp4()" @click="buyGoods(3)"
            style="margin-right: 45px">
            <img :src="imageItem4" style="width: 70px; height: 70px; padding: 10px" class="columnItem" />
          </div>

          <div @mouseover="resetBorderColor5()" @mouseout="resetBorderColorp5()" @click="buyGoodNew()"
            style="margin-right: 45px">
            <img :src="imageItem5" style="width: 70px; height: 70px; padding: 10px" class="columnItem" />
          </div>
        </div>
      </div>
    </div>
    <el-drawer title="" :visible.sync="dialogFormVisible" size="30%" direction="rtl" v-if="lange == '中文'">
      <div style="
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 83vh;
          margin-top: 6vh;
        ">
        <div>
          <div style="
              width: 80%;
              margin-bottom: 20px;
              color: #72767b;
              font-size: 16px;
              margin-left: 10%;
              display: flex;
            ">
            <div style="margin-right: 40%; color: #ff8a00">会员登录</div>
            <div @click="registerHuman">用户注册</div>
          </div>

          <div style="display: flex; margin-left: 10%; margin-bottom: 20px; font-size: 14px">
            <div>语言：</div>
            <div style="margin-left: 10%" @click="langeChange('中文')">中文</div>
            <div style="margin-left: 10%" @click="langeChange('English')">English</div>
          </div>
          <el-form ref="form" :model="form" style="width: 80%; margin-left: 10%">
            <el-form-item label="账户名称：">
              <div style="display: flex">
                <el-select v-model="form.regionId" filterable placeholder="请选择">
                  <el-option v-for="item in optionRegion" :key="item.regionId" :label="item.label"
                    :value="item.regionId">
                  </el-option>
                </el-select>
                <el-input v-model="form.usernameCopy" placeholder="账户名"></el-input>
              </div>
            </el-form-item>
            <el-form-item label="账号密码：">
              <div style="display: flex">
                <el-input v-model="form.password" placeholder="密码" show-password></el-input>
              </div>
            </el-form-item>
            <!-- <el-form-item label="营销组织：">
              <div style="display: flex">
                <el-select
                  v-model="form.organizationName"
                  placeholder="营销组织"
                  @change="selectChange"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item> -->
            <!-- <el-form-item label="语言：">
              <div style="display: flex">
                <el-select
                  v-model="lange"
                  filterable
                  placeholder="请选择"
                  @change="langeChange"
                >
                  <el-option
                    v-for="item in optionsLange"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item> -->
            <el-form-item label="验证数码：">
              <div style="display: flex">
                <el-input v-model="form.code" placeholder="验证码"></el-input>
                <div style="width: 40%">
                  <img :src="images" @click="getAdvertList" style="width: 100%; height: 40px" />
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>

        <!--  -->
        <div style="">
          <!-- <div
            style="
              width: 100%;
              justify-content: center;
              margin-top: 20px;
              display: flex;
              height: 50px;
            "
          >
            <div
              class="counter1"
              style="
                font-weight: 500;
                background: black;
                color: white;
                text-align: center;
                justify-content: center;
              "
              @click="registerHuman"
            >
              <div>注册</div>
            </div>
          </div> -->

          <div style="
              width: 100%;
              justify-content: center;
              margin-top: 20px;
              display: flex;
              height: 50px;
            ">
            <div class="counter1" style="
                font-weight: 500;
                background: black;
                color: white;
                text-align: center;
                justify-content: center;
              " @click="handleLogin">
              <div>登陆</div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
    <el-drawer title="" :visible.sync="dialogFormVisible" size="30%" direction="rtl" v-else>
      <div style="
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 83vh;
          margin-top: 6vh;
        ">
        <div>
          <div style="
              width: 80%;
              margin-bottom: 20px;
              color: #72767b;
              font-size: 16px;
              margin-left: 10%;
              display: flex;
            ">
            <div style="margin-right: 40%; color: #ff8a00">Member Log In</div>
            <div @click="registerHuman">User Registration</div>
          </div>
          <div style="display: flex; margin-left: 10%; margin-bottom: 20px; font-size: 14px">
            <div>Lange：</div>
            <div style="margin-left: 10%" @click="langeChange('中文')">中文</div>
            <div style="margin-left: 10%" @click="langeChange('English')">English</div>
          </div>
          <el-form ref="form" :model="form" style="width: 80%; margin-left: 10%">
            <el-form-item label="Account Number:">
              <div style="display: flex">
                <el-select v-model="form.regionId" filterable placeholder=" ">
                  <el-option v-for="item in optionRegion" :key="item.regionId" :label="item.label"
                    :value="item.regionId">
                  </el-option>
                </el-select>
                <el-input v-model="form.usernameCopy" placeholder=""></el-input>
              </div>
            </el-form-item>
            <el-form-item label="Password：">
              <div style="display: flex">
                <el-input v-model="form.password" placeholder="" show-password></el-input>
              </div>
            </el-form-item>
            <!-- <el-form-item label="Sales Organization：">
              <div style="display: flex">
                <el-select
                  v-model="form.organizationName"
                  placeholder=""
                  @change="selectChange"
                >
                  <el-option
                    v-for="item in optionsEnglish"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item> -->
            <!-- <el-form-item label="Lange：">
            <div style="display: flex">
              <el-select v-model="lange" filterable placeholder="" @change="langeChange">
                <el-option
                  v-for="item in optionsLange"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item> -->
            <el-form-item label="Verification Code：">
              <div style="display: flex">
                <el-input v-model="form.code" placeholder=""></el-input>
                <div style="width: 40%">
                  <img :src="images" @click="getAdvertList" style="width: 100%; height: 40px" />
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <!--  -->
        <div style="">
          <!-- <div
            style="
              width: 100%;
              justify-content: center;
              margin-top: 20px;
              display: flex;
              height: 50px;
            "
          >
            <div
              class="counter1"
              style="
                font-weight: 500;
                background: black;
                color: white;
                text-align: center;
                justify-content: center;
              "
              @click="registerHuman"
            >
              <div v-if="lange == '中文'">注册</div>

              <div v-else>Register</div>
            </div>
          </div> -->

          <div style="
              width: 100%;
              justify-content: center;
              margin-top: 20px;
              display: flex;
              height: 50px;
            ">
            <div class="counter1" style="
                font-weight: 500;
                background: black;
                color: white;
                text-align: center;
                justify-content: center;
              " @click="handleLogin">
              <div v-if="lange == '中文'">登陆</div>
              <div v-else>Login</div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
    <el-drawer title="" :visible.sync="dialogFormVisibles" size="30%" v-if="lange == '中文'">
      <div style="
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 83vh;
          margin-top: 6vh;
        ">
        <el-form :model="formRegister" label-position="left" style="width: 80%; margin-left: 10%" :rules="rules"
          ref="ruleForm">
          <div style="
              margin-bottom: 20px;
              color: #72767b;
              font-size: 16px;

              display: flex;
            ">
            <div style="margin-right: 40%" @click="dialogFormVisibles = false">
              会员登录
            </div>
            <div style="color: #ff8a00">用户注册</div>
          </div>
          <div style="display: flex; margin-bottom: 20px; font-size: 14px">
            <div>语言：</div>
            <div style="margin-left: 10%" @click="langeChange('中文')">中文</div>
            <div style="margin-left: 10%" @click="langeChange('English')">English</div>
          </div>
          <el-form-item prop="phoneCopy">
            <div style="display: flex">
              <div style="width: 280px">手机号码：</div>
              <el-select v-model="formRegister.regionId" filterable placeholder="请选择">
                <el-option v-for="item in optionRegion" :key="item.regionId" :label="item.label" :value="item.regionId">
                </el-option>
              </el-select>
              <el-input v-model="formRegister.phoneCopy" placeholder="手机号码"></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="password">
            <div style="display: flex">
              <div style="width: 180px">密码：</div>
              <el-input v-model="formRegister.password" show-password placeholder="密码"></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="passwordOther">
            <div style="display: flex">
              <div style="width: 180px">确认密码：</div>
              <el-input v-model="formRegister.passwordOther" show-password placeholder="确认密码"></el-input>
            </div>
          </el-form-item>
          <!-- <el-form-item>
            <div style="display: flex">
              <div style="width: 130px">语言</div>
              <el-select v-model="lange" filterable placeholder="" @change="langeChange">
                <el-option
                  v-for="item in optionsLange"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item> -->
          <el-form-item prop="customername">
            <div style="display: flex">
              <div style="width: 180px">公司名称：</div>
              <el-input v-model="formRegister.customername" placeholder="公司名称"></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="linkman">
            <div style="display: flex">
              <div style="width: 180px">客户联系人：</div>
              <el-input v-model="formRegister.linkman" placeholder="联系人"></el-input>
            </div>
          </el-form-item>

          <!-- <el-form-item>
            <el-input v-model="form.username" placeholder="联系人"></el-input>
          </el-form-item> -->
        </el-form>
        <!--  -->
        <div style="
            width: 100%;
            justify-content: center;
            margin-top: 20px;
            display: flex;
            height: 50px;
          ">
          <div class="counter1" style="
              font-weight: 500;
              background: black;
              color: white;
              text-align: center;
              justify-content: center;
            " @click="formRegisterClick">
            <div>注册</div>
          </div>
        </div>
      </div>
    </el-drawer>
    <el-drawer title="" :visible.sync="dialogFormVisibles" size="30%" v-else>
      <div style="
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 83vh;
          margin-top: 6vh;
        ">
        <el-form :model="formRegister" label-position="left" style="width: 80%; margin-left: 10%" :rules="rules"
          ref="ruleForm">
          <div style="
              margin-bottom: 20px;
              color: #72767b;
              font-size: 16px;

              display: flex;
            ">
            <div style="margin-right: 40%" @click="dialogFormVisibles = false">
              Member Log In
            </div>
            <div style="color: #ff8a00">Register an account</div>
          </div>
          <div style="display: flex; margin-bottom: 20px; font-size: 14px">
            <div>Lange：</div>
            <div style="margin-left: 10%" @click="langeChange('中文')">中文</div>
            <div style="margin-left: 10%" @click="langeChange('English')">English</div>
          </div>
          <el-form-item prop="phoneCopy">
            <div style="display: flex">
              <div style="width: 280px">phone number：</div>
              <el-select v-model="formRegister.regionId" filterable placeholder="">
                <el-option v-for="item in optionRegion" :key="item.regionId" :label="item.label" :value="item.regionId">
                </el-option>
              </el-select>
              <el-input v-model="formRegister.phoneCopy" placeholder=""></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="password">
            <div style="display: flex">
              <div style="width: 225px">password：</div>
              <el-input v-model="formRegister.password" show-password placeholder=""></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="passwordOther">
            <div style="display: flex">
              <div style="width: 225px">Confirm password：</div>
              <el-input v-model="formRegister.passwordOther" show-password placeholder=""></el-input>
            </div>
          </el-form-item>
          <!-- <el-form-item>
            <div style="display: flex">
              <div style="width: 130px">Lange</div>
              <el-select v-model="lange" filterable placeholder="" @change="langeChange">
                <el-option
                  v-for="item in optionsLange"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item> -->
          <el-form-item prop="customername">
            <div style="display: flex">
              <div style="width: 225px">Company Name：</div>
              <el-input v-model="formRegister.customername" placeholder=""></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="linkman">
            <div style="display: flex">
              <div style="width: 225px">Customer Contacts：</div>
              <el-input v-model="formRegister.linkman" placeholder=""></el-input>
            </div>
          </el-form-item>

          <!-- <el-form-item>
            <div style="display: flex">
              <div style="width: 180px">duty paragraph：</div>
              <el-input v-model="formRegister.taxOrIdentityID" placeholder=""></el-input>
            </div>
          </el-form-item>
          <el-form-item>
            <div style="display: flex">
              <div style="width: 180px">introducer：</div>
              <el-input v-model="formRegister.referee" placeholder=""></el-input>
            </div>
          </el-form-item> -->
          <!-- <el-form-item>
            <el-input v-model="form.username" placeholder="联系人"></el-input>
          </el-form-item> -->
        </el-form>
        <!--  -->
        <div style="
            width: 100%;
            justify-content: center;
            margin-top: 20px;
            display: flex;
            height: 50px;
          ">
          <div class="counter1" style="
              font-weight: 500;
              background: black;
              color: white;
              text-align: center;
              justify-content: center;
            " @click="formRegisterClick">
            <div v-if="lange == '中文'">注册</div>

            <div v-else>Register</div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getCaptchaImage } from "@/api/other/index.js";
import { getSaveCustomerMsg, getQueryRegion, register } from "@/api/auth";
import { mapGetters } from "vuex";
export default {
  name: "",
  computed: {
    backGroungStyle() {
      return {
        background:
          this.brandName == "CHRISBELLA"
            ? "#a5ced0"
            : this.brandName == "SUSEN零售"
              ? "#f9e5cd"
              : "#ffcccb",
      };
    },
    ...mapGetters({
      lange: "user/lange",
      organizationName: "user/organizationName",
      priceChangeList: "user/priceChangeList",
    }),
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.formRegister.passwordOther !== "") {
          this.$refs.ruleForm.validateField("passwordOther");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.formRegister.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var validatePass3 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入手机号码"));
      } else {
        callback();
      }
    };
    var validatePass4 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入公司名称"));
      } else {
        callback();
      }
    };
    var validatePass5 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入客户联系人"));
        console.log(1);
      } else {
        callback();
      }
    };
    var validatePass6 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择所属国家"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        password: "",
        passwordOther: "",
        phoneCopy: "",
        customername: "",
        linkman: "",
        regionId: "",
      },
      loading: true,
      rules: {
        password: [{ required: true, validator: validatePass, trigger: "blur" }],
        passwordOther: [{ required: true, validator: validatePass2, trigger: "blur" }],
        phoneCopy: [{ required: true, validator: validatePass3, trigger: "blur" }],
        customername: [{ required: true, validator: validatePass4, trigger: "blur" }],
        linkman: [{ required: true, validator: validatePass5, trigger: "blur" }],
        regionId: [{ required: true, validator: validatePass6, trigger: "blur" }],
      },

      dialogFormVisible: false,
      dialogFormVisibles: false,
      imageItem1: "https://www.aopiya.com/file/abyFile/backGround/1.png",
      imageItem2: "https://www.aopiya.com/file/abyFile/backGround/2.png",
      imageItem3: "https://www.aopiya.com/file/abyFile/backGround/3.png",
      imageItem4: "https://www.aopiya.com/file/abyFile/backGround/4.png",
      imageItem5: "https://www.aopiya.com/file/abyFile/backGround/5.png",
      autoplayTrue: true,
      isLogin: false,

      articleList: [],
      articleList2: [],
      hotgoodsList: [],

      newestGoodsList: [],
      newestGoodsListg: [],
      advertList: [],
      hotNull: 0,
      newNull: 0,
      swiperOptions: {
        loop: true,
        autoplay: true,
        initialSlide: 1,
      },
      organizationNameListShow: false,
      form: {},
      formRegister: { phoneCopy: "" },
      images: "",
      imagesOther: "",
      optionRegion: [],
      options: [
        {
          value: "广州",
          label: "广州",
        },
        {
          value: "迪拜",
          label: "迪拜",
        },
      ],
      optionsEnglish: [
        {
          value: "广州",
          label: "Guangzhou",
        },
        {
          value: "迪拜",
          label: "Dubai",
        },
      ],
      optionsLange: [
        {
          value: "中文",
          label: "中文",
        },
        {
          value: "English",
          label: "English",
        },
      ],
    };
  },
  created() {
    var inviteCode = this.$route.query.inviteCode;
    if (inviteCode) {
      localStorage.setItem("inviteCode", inviteCode);
    }
    let token = localStorage.getItem("dftToken");
    if (token) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
    // this.getArticle();
    // this.getArticle2();
    // this.getHotGoodsList();
    this.getAdvertList();
    // this.IsPC()
    console.log(this.priceChangeList);
  },
  mounted() { },
  watch: {},
  methods: {
    langeChange(event) {
      this.$store.dispatch("user/setLange", event);

      localStorage.setItem("lange", event);
    },
    // selectChange(event) {
    //   this.$store.dispatch("user/setOrganizationName", event);
    //   localStorage.setItem("organizationName", event);
    // },
    formRegisterClick() {
      if (this.loading == false) {
        this.$notify({
          title: this.lange == "中文" ? "错误操作" : "Error",
          message:
            this.lange == "中文"
              ? "正在注册中请勿重复点击按钮"
              : "Please do not click the button again while registering",
          type: "error",
          duration: 0,
          offset: 100,
        });
      } else {
        this.loading = false;

        let quhao = this.optionRegion.filter((item) => {
          return item.regionid == this.formRegister.regionId;
        })[0];
        if (quhao) {
          this.formRegister.phone = "00" + quhao.phoneCode + this.formRegister.phoneCopy;
        }
        console.log(quhao);
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            register(this.formRegister).then((res) => {
              if (res.code == "200") {
                this.$notify({
                  title:
                    this.lange == "中文"
                      ? "注册申请已提交"
                      : "Registration application has been submitted",
                  message:
                    this.lange == "中文"
                      ? "已成功注册，注册通过后将会发送短信给您！"
                      : "Registration is done. You will receive a message when it’s approved",
                  type: "success",
                  duration: 0,
                  offset: 100,
                });
                this.dialogFormVisible = false;
                this.dialogFormVisibles = false;
                this.loading = true;
              } else {
                this.loading = true;
              }
            });
          } else {
            this.$notify({
              title: this.lange == "中文" ? "错误操作" : "Error",
              message:
                this.lange == "中文"
                  ? "请将信息填写完整"
                  : "Please fill in the information completely",
              type: "error",
              duration: 0,
              offset: 100,
            });
            console.log("error submit!!");
            this.loading = true;
            return false;
          }
        });
      }
    },
    registerHuman() {
      this.dialogFormVisibles = true;
      getQueryRegion({}).then((res) => {
        this.optionRegion = res.data.list;
        this.optionRegion.forEach((item) => {
          item.label =
            "00" +
            item.phoneCode +
            "-" +
            item.regionsimplechinesename +
            "-" +
            item.regionname;
        });
      });
    },
    handleLogin() {
      this.loading = true;
      let quhao = this.optionRegion.filter((item) => {
        return item.regionid == this.form.regionId;
      })[0];
      console.log(quhao);
      if (quhao) {
        this.form.username = "00" + quhao.phoneCode + this.form.usernameCopy;
      }
      this.$store
        .dispatch("user/login", this.form)
        .then(async () => {
          localStorage.setItem("loginAccount", this.form.username);

          this.Flag = localStorage.getItem("flag");
          console.log(this.redirect);
          getSaveCustomerMsg({
            brandName: this.form.brandName,
            organizationName
          }).then((res) => {
            if (res.code == 200) {
              localStorage.setItem("brandName", this.form.brandName);
              sessionStorage.setItem('headImageUrl', res.data.headImageUrl)
              this.$store.dispatch(
                "user/setorganizationId",
                res.data.organizationId
              );
              localStorage.setItem("organizationId", res.data.organizationId);  
              if (res.data.organizationId == "1") {
                localStorage.setItem("organizationNames", "广州");
              }else{
                localStorage.setItem("organizationNames", "迪拜");
              }
              localStorage.setItem("sellOrganization", res.data.sellOrganization);
              console.log(res.data)
              localStorage.setItem("userContent", JSON.stringify(res.data));
              this.$store.dispatch(
                "user/setPriceChangeList",
                JSON.stringify(res.data.customerpriceadjustments)
              );
              localStorage.setItem(
                "priceChangeList",
                JSON.stringify(res.data.customerpriceadjustments)
              );
              console.log(this.priceChangeList);
              localStorage.setItem("userId", res.data.userId);

              let organizationNameList = res.data.sellOrganization.split(",");
              if (organizationNameList.length != 1) {
                localStorage.setItem("organizationName", "['广州','迪拜']");
                localStorage.setItem("organizationName", "['广州','迪拜']");

                this.$store.dispatch("user/setOrganizationName", "['广州','迪拜']");
              } else {
                if (organizationNameList[0] == "1") {
                  localStorage.setItem("organizationName", "广州");
                  localStorage.setItem("organizationNames", "广州");
                  this.$store.dispatch("user/setOrganizationName", "广州");
                } else {
                  localStorage.setItem("organizationName", "迪拜");
                  localStorage.setItem("organizationNames", "迪拜");
                  this.$store.dispatch("user/setOrganizationName", "迪拜");
                }
              }
              window.open(
                this.$locationViewUrl +
                "#/goods/GoodsHome?brandName=" +
                this.form.brandName,
                "_blank"
              );
              this.dialogFormVisible = false;
            } else {
              this.$notify.error({
                title: "错误",
                message: res.msg,
              });
              this.form = {};
            }
          });
          // let routerPath =
          // this.redirect === "/404" || this.redirect === "/401" ? "/" : this.redirect;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    buyGoodIntroduce() {
      this.$router.push("/Introduce");

    },
    buyGoodNew() {
      this.$router.push("/news");

    },
    buyGoods(item) {
      if (item == 1) {
        this.form.brandName = "CHRISBELLA";
      } else if (item == 2) {
        this.form.brandName = "SUSEN零售";
      } else {
        this.form.brandName = "BAGCO";
      }
      // if (this.organizationName) {
      //   let organizationNameList = this.organizationName.split(",");
      //   let organizationNameOther=''
      //   if (organizationNameList.length == 1) {
      //      organizationNameOther = organizationNameList[0];
      //   } else {
      //     organizationNameOther = "广州";
      //   }
      //   getSaveCustomerMsg({
      //     brandName: this.form.brandName,
      //     organizationName: organizationNameOther,
      //   }).then((res) => {
      //     console.log(res);
      //     if (res.code == 200) {
      //       localStorage.setItem("brandName", this.form.brandName);
      //       window.open(this.$locationViewUrl + "#/goods/GoodsHome", "_blank");
      //     } else {
      //       this.dialogFormVisible = true;
      //       getCaptchaImage().then((res) => {
      //         if (res.code == 200) {
      //           this.images = "data:image/gif;base64," + res.data.img;
      //           console.log(this.images);
      //           this.form.uuid = res.data.uuid;
      //           localStorage.setItem("uuid", res.data.uuid);
      //           getQueryRegion({}).then((res) => {
      //             this.optionRegion = res.data.list;
      //             this.optionRegion.forEach((item) => {
      //               item.label =
      //                 "00" +
      //                 item.phoneCode +
      //                 "-" +
      //                 item.regionsimplechinesename +
      //                 "-" +
      //                 item.regionname;
      //             });
      //           });
      //         }
      //       });
      //     }
      //   });
      // } else {
      getSaveCustomerMsg({
        brandName: this.form.brandName,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          console.log(res.data)
          localStorage.setItem("userContent", JSON.stringify(res.data));
          sessionStorage.setItem('headImageUrl', res.data.headImageUrl)
          localStorage.setItem("brandName", this.form.brandName);
          localStorage.setItem("sellOrganization", res.data.sellOrganization);
          this.$store.dispatch(
            "user/setPriceChangeList",
            JSON.stringify(res.data.customerpriceadjustments)
          );
          
          this.$store.dispatch(
                "user/setorganizationId",
                res.data.organizationId
              );
              localStorage.setItem("organizationId", res.data.organizationId);  
              if (res.data.organizationId == "1") {
                localStorage.setItem("organizationNames", "广州");
              }else{
                localStorage.setItem("organizationNames", "迪拜");
              }
          localStorage.setItem(
            "priceChangeList",
            JSON.stringify(res.data.customerpriceadjustments)
          );
          window.open(
            this.$locationViewUrl + "#/goods/GoodsHome?brandName=" + this.form.brandName,
            "_blank"
          );
        } else {
          this.dialogFormVisible = true;

          getCaptchaImage().then((res) => {
            if (res.code == 200) {
              this.images = "data:image/gif;base64," + res.data.img;
              console.log(this.images);
              this.form.uuid = res.data.uuid;
              localStorage.setItem("uuid", res.data.uuid);
              getQueryRegion({}).then((res) => {
                this.optionRegion = res.data.list;
                this.optionRegion.forEach((item) => {
                  item.label =
                    "00" +
                    item.phoneCode +
                    "-" +
                    item.regionsimplechinesename +
                    "-" +
                    item.regionname;
                });
              });
            }
          });
        }
      });
      // }
    },

    resetBorderColor1() {
      this.imageItem1 = "https://www.aopiya.com/file/abyFile/backGround/09.png";
    },
    resetBorderColor2() {
      this.imageItem2 = "https://www.aopiya.com/file/abyFile/backGround/11.png";
    },
    resetBorderColor3() {
      this.imageItem3 = "https://www.aopiya.com/file/abyFile/backGround/10.png";
    },
    resetBorderColor4() {
      this.imageItem4 = "https://www.aopiya.com/file/abyFile/backGround/12.png";
    },
    resetBorderColor5() {
      this.imageItem5 = "https://www.aopiya.com/file/abyFile/backGround/13.png";
    },
    resetBorderColorp1() {
      this.imageItem1 = "https://www.aopiya.com/file/abyFile/backGround/1.png";
    },
    resetBorderColorp2() {
      this.imageItem2 = "https://www.aopiya.com/file/abyFile/backGround/2.png";
    },
    resetBorderColorp3() {
      this.imageItem3 = "https://www.aopiya.com/file/abyFile/backGround/3.png";
    },
    resetBorderColorp4() {
      this.imageItem4 = "https://www.aopiya.com/file/abyFile/backGround/4.png";
    },
    resetBorderColorp5() {
      this.imageItem5 = "https://www.aopiya.com/file/abyFile/backGround/5.png";
    },
    changselect(index, indexPath) {
      this.$router.push("/IndexThree");
    },

    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      if (!flag) {
        this.$router.push("/m_index");
      }
    },
    backgroundImg(value) {
      var host = document.location.host;
      var result = "";
      if (value && value.indexOf("files") != -1) {
        if (host.search("192") != -1) {
          result = "http://192.168.0.50:8080" + value;
        } else {
          result = document.location.protocol + "//" + host + "/" + value;
        }
      } else {
        result = value;
      }
      return result;
    },
    //公告列表
    getArticle() {
      this.$request.post({
        url: "/ReportApi/business/newlists",
        params: {
          pageNo: 1,
          pageSize: 3,
          type: 1,
        },
        success: (res) => {
          this.articleList = res[0];
        },
      });
    },
    //新闻列表
    getArticle2() {
      this.$request.post({
        url: "/ReportApi/business/newlists",
        params: {
          pageNo: 1,
          pageSize: 6,
          type: 2,
        },
        success: (res) => {
          this.articleList2 = res[0];
        },
      });
    },
    //热销商品列表
    getHotGoodsList() {
      this.$request.post({
        url: "/ReportApi/business/goodlist",
        params: {
          pageNo: 1,
          pageSize: 11,
          sort: 3,
          tenantid: localStorage.getItem("tenantInfoid"),
        },
        success: (res) => {
          this.hotgoodsList = res[0];
          if (this.hotgoodsList.length < 11) {
            this.hotNull = 11 - this.hotgoodsList.length;
          }
          this.getNewestGoodsList();
        },
      });
    },
    //最新商品
    getNewestGoodsList() {
      this.$request.post({
        url: "/ReportApi/business/goodlist",
        params: {
          pageNo: 1,
          pageSize: 11,
          sort: 5,
          tenantid: localStorage.getItem("tenantInfoid"),
        },
        success: (res) => {
          this.newestGoodsList = res[0];
          if (this.newestGoodsList.length < 11) {
            this.newNull = 11 - this.newestGoodsList.length;
          }
        },
      });
    },
    buyGoodp(item) {
      // this.$store.commit('getGoodsInfo', item)
      this.$router.push("/MallHomepage");
    },
    buyGood(item) {
      // this.$store.commit('getGoodsInfo', item)
      this.$router.push("/goods/ImageGoodList");
    },
    openQQ() {
      var url =
        "tencent://message/?uin=" +
        this.$store.state.tenantInfo.qq +
        "&amp;Site=&amp;Menu=yes";
      window.open(url, "_self");
    },
    getAdvertList() {
      getCaptchaImage().then((res) => {
        if (res.code == 200) {
          this.images = "data:image/gif;base64," + res.data.img;
          console.log(this.images);
          this.form.uuid = res.data.uuid;
          localStorage.setItem("uuid", res.data.uuid);
        }
      });
    },
    toUrl(url) {
      if (url.indexOf("http") != -1) {
        window.open(url);
      } else {
        this.$router.push(url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#home {
  height: 100%;

  .header_wrap {
    min-height: 100%;

    .main {

      // padding-bottom: 200px;
      .header {
        .top_header {
          background: #f5f5f5;
          border-bottom: #eaeaea;

          a {
            color: #999;

            &:hover {
              color: #333;
            }
          }

          .wrap {
            width: 1400px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 39px;
            color: #999;
            font-size: 12px;
          }

          .top_right {
            li {
              padding: 0 15px;
              border-right: 1px solid #e1e1e1;
              display: inline-block;
              cursor: pointer;
            }
          }
        }

        .center_header {
          height: 125px;
          width: 1400px;

          // width: 1265px;
          .logo {
            width: 220px;
            height: 105px;
            margin-top: 10px;
          }

          .nav {
            margin-left: 50px;

            ul {
              float: left;
              // width: 688px;
              width: 610px;
              display: block;
              margin-top: 50px;
              margin-right: 30px;
            }

            li {
              float: left;
              margin: 0 4px;
              padding: 0 4px;
              font-size: 16px;
              color: #999;

              &:hover {
                cursor: pointer;
                color: #f80;
              }
            }

            .router-link-exact-active {
              color: #f80;
            }
          }

          .head_menu_user {
            margin-top: 50px;

            img {
              margin-right: 10px;
            }

            .info {
              color: #999;
              line-height: 25px;
              font-size: 14px;

              .red {
                color: #f80;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-flex;
                max-width: 90px;
              }

              .logout {
                margin-left: 15px;
                cursor: pointer;
              }
            }
          }
        }
      }

      .login_header {
        padding-top: 30px;

        .login_img {
          display: flex;
          align-items: center;

          div:hover {
            cursor: pointer;
          }

          p {
            color: #8c8c8c;
            margin-left: 15px;
          }
        }

        .login_text {
          line-height: 130px;
          font-size: 14px;

          a {
            color: #f80;
          }
        }
      }
    }
  }

  .footer_wrap {
    height: 200px;
    // margin-top: -200px;
    padding-top: 30px;

    ul {
      li {
        display: inline-block;
        width: 270px;
        height: 56.5px;

        img {
          width: 74px;
          margin-top: -7px;
        }

        .info {
          display: inline-block;
          margin-left: 20px;
          vertical-align: top;

          .title {
            color: #333;
            font-size: 16px;
            font-weight: bold;
            line-height: 36px;
          }

          .desc {
            font-size: 12px;
            color: #999;
          }
        }

        &:not(:first-child) {
          margin-left: 33px;
        }
      }
    }

    .copyright {
      text-align: center;
      font-size: 12px;
      color: #666;
      margin-top: 48px;
      clear: both;

      .jc {
        line-height: 50px;
      }

      .link {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        p {
          display: flex;
          flex-wrap: wrap;
        }

        a {
          margin-right: 6px;
          white-space: nowrap;
        }
      }
    }
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
  height: 100vh;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 154px;
  min-height: 400px;
}

#index {
  .banner {
    position: relative;
    cursor: pointer;
  }

  .banner_sub_width {
    height: 400px;

    .banner_sub {
      width: 320px;
      margin-top: 10px;
      height: 400px;
      background: #fff;
      border-radius: 4px;
    }

    .login_avatar {
      color: #999;
      text-align: center;
      font-size: 19px;

      img {
        border-radius: 50%;
        margin-bottom: 10px;
        margin-top: 10px;
        width: 80px;
        height: 80px;
      }
    }

    .to_user_center {
      width: 220px;
      padding: 48px 0;

      span {
        background: #f80;
        color: #fff;
        line-height: 30px;
        width: 360px;
        text-align: center;
        border-radius: 4px;
        margin: 0 auto;
        display: block;
        cursor: pointer;
      }
    }

    .login_btn {
      margin-top: 10px;

      .left_login {
        width: 83px;
        line-height: 31px;
        font-size: 14px;
        border: 1px solid #f80;
        border-radius: 3px;
        margin-left: 28px;
        text-align: center;

        &:hover {
          cursor: pointer;
        }

        &:first-child {
          color: #f80;

          &:hover {
            background: #f80;
            color: #fff;
          }
        }

        &:last-child {
          background: #f80;
          color: #fff;
        }
      }
    }

    .sub_gg {
      padding: 8px 25px;
      box-sizing: border-box;
      margin-top: 10px;
      border-top: 1px solid #f1f1f1;
      font-size: 12px;

      h3 {
        font-size: 14px;
        margin-bottom: 5px;
      }

      li {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 25px;
        color: #999;
      }
    }

    .other_block {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .block {
        border-top: 1px solid #dedede;
        float: left;
        width: 140px;
        height: 73px;
        background: #fbfbfb;
        box-sizing: border-box;
        font-size: 14px;
        text-align: center;

        &:first-child,
        &:nth-child(3) {
          border-right: 1px solid #dedede;
        }

        img {
          width: 30px;
          height: 30px;
          margin-top: 8px;
          margin-bottom: 5px;
        }

        &:hover {
          background: #eaeaea;
          cursor: pointer;
        }
      }
    }
  }

  .main {
    .advertising {
      display: flex;
      margin-top: 10px;

      div {
        flex: 1;

        img {
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }

    .recommend {
      margin-top: 20px;

      .item {
        height: 470px;
        margin-left: 20px;
        margin-bottom: 12px;

        .recommend_left {
          height: 280px;
          width: 360px;

          img {
            width: 360px;
            height: 280px;
          }

          &:hover {
            cursor: pointer;
          }
        }

        &:nth-child(1),
        &:nth-child(4n + 1) {
          margin-left: 0;
        }

        dl {
          width: 360px;
          border: 1px solid #eee;

          &:hover {
            cursor: pointer;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
          }

          img {
            width: 360px;
            height: 360px;
          }

          dd {
            padding: 0 5px;
          }

          .title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 12px;
            color: #999;
            line-height: 28px;
          }

          .other {
            .price {
              font-size: 18px;
              color: #f80;
            }

            .wei {
              font-size: 14px;
              color: #666;
            }
          }

          .btn {
            width: 243px;
            line-height: 28px;
            text-align: center;
            color: #f80;
            border: 1px solid #f80;
            box-sizing: border-box;
            border-radius: 4px;
            margin: 6px auto 10px auto;
            display: block;
            height: 28px;
            font-size: 14px;

            &:hover {
              color: #fff;
              background: #f80;
            }
          }
        }
      }

      .items {
        height: 470px;
        margin-left: 10px;
        margin-bottom: 12px;

        .recommend_left {
          height: 280px;
          width: 360px;

          img {
            width: 360px;
            height: 280px;
          }

          &:hover {
            cursor: pointer;
          }
        }

        &:nth-child(1),
        &:nth-child(3n + 1) {
          margin-left: 0;
        }

        dl {
          width: 360px;
          border: 1px solid #eee;

          &:hover {
            cursor: pointer;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
          }

          img {
            width: 360px;
            height: 360px;
          }

          dd {
            padding: 0 5px;
          }

          .title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 12px;
            color: #999;
            line-height: 28px;
          }

          .other {
            .price {
              font-size: 18px;
              color: #f80;
            }

            .wei {
              font-size: 14px;
              color: #666;
            }
          }

          .btn {
            width: 243px;
            line-height: 28px;
            text-align: center;
            color: #f80;
            border: 1px solid #f80;
            box-sizing: border-box;
            border-radius: 4px;
            margin: 6px auto 10px auto;
            display: block;
            height: 28px;
            font-size: 14px;

            &:hover {
              color: #fff;
              background: #f80;
            }
          }
        }
      }

      .null {
        width: 188px;
        border: 1px solid #eee;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .new_goods_title_hot {
      height: 40px;
      border-bottom: 1px solid #efefef;
      margin-top: 40px;
      margin-bottom: 30px;

      span {
        font-size: 20px;
        line-height: 28px;
        border-left: 5px solid #f80;
        padding-left: 15px;
        font-weight: bold;
        font-size: 20px;
      }
    }

    .new_goods_title_new {
      height: 40px;
      border-bottom: 1px solid #efefef;
      margin-top: 40px;
      margin-bottom: 30px;

      span {
        font-size: 20px;
        line-height: 28px;
        border-left: 5px solid #f80;
        padding-left: 15px;
        font-weight: bold;
        font-size: 20px;
      }
    }

    .new_goods_title_new1 {
      height: 40px;
      border-bottom: 1px solid #efefef;
      margin-top: 40px;
      margin-bottom: 30px;

      span {
        font-size: 20px;
        line-height: 28px;
        border-left: 5px solid #f80;
        padding-left: 15px;
        font-weight: bold;
        font-size: 20px;
      }
    }

    .new_goods_title_new2 {
      height: 40px;
      border-bottom: 1px solid #efefef;
      margin-top: 40px;
      margin-bottom: 30px;

      span {
        font-size: 20px;
        line-height: 28px;
        border-left: 5px solid #f80;
        padding-left: 15px;
        font-weight: bold;
        font-size: 20px;
      }
    }

    .new_goods_title_Image_store {
      height: 40px;
      border-bottom: 1px solid #efefef;
      margin-top: 40px;
      margin-bottom: 30px;

      span {
        font-size: 20px;
        line-height: 28px;
        border-left: 5px solid #f80;
        padding-left: 15px;
        font-weight: bold;
        font-size: 20px;
      }
    }

    .section4 {
      position: relative;
      text-align: center;
      margin-bottom: 25px;
      background: #fff;

      &>span {
        position: relative;
        display: inline-block;
        margin: 25px auto;
        font-weight: 700;
        font-size: 22px;

        &::after {
          content: "";
          position: absolute;
          bottom: -10px;
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
          display: inline-block;
          background: -webkit-gradient(linear,
              left top,
              right top,
              from(rgba(255, 166, 0, 0.929412)),
              to(#ff6a00));
          background: linear-gradient(90deg, rgba(255, 166, 0, 0.929412), #ff6a00);
          width: 40%;
          border-radius: 50px;
          height: 4px;
        }
      }

      .article_list {
        text-align: left;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-wrap: wrap;

        .article_item {
          border: 1px solid #eee;
          padding: 10px 30px;
          margin: 10px 0;
          width: 44%;
          cursor: pointer;

          &:hover {
            box-shadow: 0 0 5px 1px #dcdfe6;

            .title a {
              color: #ff6a00;
            }
          }

          .title {
            display: -webkit-box;
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
            margin-bottom: 10px;
          }

          .summary {
            color: #767676;
            font-size: 14px;
          }

          .title a {
            font-size: 18px;
            color: #333;
          }

          .title {
            p {
              color: #999;
            }
          }
        }
      }
    }
  }

  .xian {
    height: 5px;
    background: #eee;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}

.columnItem {
  &:hover {
    width: 81px;
    height: 81px;
  }
}
</style>
<style lang="scss" scoped>
#home {
  height: 100%;

  .counter1 {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 70%;
    padding: 5px;
    border: 1px solid #8e8e8e;
    position: relative;
    line-height: 31px;
    border-radius: 40px;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* 将页面分为3列，每列宽度平均 */
    // grid-gap: 10px; /* 设置列之间的间距 */
    height: 100vh;
  }

  /* 样式化每列 */
  .column {
    background-color: #f0f0f0;

    text-align: center;
    height: 100%;

    &:hover {
      cursor: pointer;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
    }
  }
}
</style>
